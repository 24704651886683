import LinkedResource from "./LinkedResource/LinkedResource";

/* English notes to display. */
const notesEng = [
  // {
  //   fileName: "ComplexRudiments.pdf",
  //   name: "Rudimentary Aspects of Complex Analysis",
  //   synopsis: "Rudimentary aspects of complex analysis in terms of Banach algebras. Supplementary reading for the course MAT2410 – Complex Analysis at the University of Oslo autumn 2022."
  // },
  // {
  //   fileName: "CommentsOnUrysohn.pdf",
  //   name: "Comments on Urysohn's Lemma",
  //   synopsis: "Suggests the concept of stable partial maps and choice recursion to clarify a technical point in the standard proof of Urysohn's lemma about which most authors remain vague."
  // }
  {
    fileName: "foldvik-uiomsc-pres.pdf",
    name: "MSc Presentation UiO (2024)",
    synopsis: "Master's presentation at the University of Oslo (2024)."
  }
];

function noteDataToListEntries(notes) {
  return notes.map(({ fileName, name, synopsis }, index) => {
    return (
      <li key={ index }>
        <LinkedResource url={ `notes/${fileName}` } title={ name } description={ synopsis } />
      </li>
    );
  });
}

function NotesSection({ title, notes }) {
  return (
    <section>
      <h3>{ title }</h3>
      <ul>
        { noteDataToListEntries(notes) }
        <li key="foldvik-uiomsc.pdf">
          <LinkedResource url="https://www.duo.uio.no/handle/10852/116556" title="MSc Thesis UiO (2024)" description="Master's thesis at the University of Oslo (2024)." />
        </li>
      </ul>
    </section>
  );
}

export default function Notes() {
  return (
    <section>
      <h2>Mathematics</h2>
      <NotesSection title="" notes={ notesEng } />
    </section>
  );
}
